<template>
  <section class="category_section">
    <Showcase title="Mobile multiselect, triggers native select">
      <MultiselectMobile
        :options="mselect.options"
        :availableOptions="mselect.filteredOptions"
        :name="mselect.name"
      />
    </Showcase>
    <Showcase title="Single select, (native select mobile)">
      <SingleSelect v-model="singleSelectValue" :options="singleSelectData" />
    </Showcase>
    <Showcase title="Rating">
      <Rating v-model="rating" /> {{ rating }}
    </Showcase>
    <Showcase title="Input">
      <Field
        v-model="input"
        name="phone"
        type="tel"
        placeholder="telefonnummer"
      />
    </Showcase>
  </section>
</template>

<script>
// @ is an alias to /src
import Showcase from '@/components/Showcase.vue';
import MultiselectMobile from '@/components/ui/MultiselectMobile.vue';
import SingleSelect from '@/components/ui/SingleSelect.vue';
import Rating from '@/components/ui/Rating.vue';
import Field from '@/components/ui/Field.vue';

export default {
  name: 'Home',
  components: {
    Showcase,
    MultiselectMobile,
    SingleSelect,
    Rating,
    Field,
  },
  data() {
    return {
      mselect: {
        options: [
          { name: 'Val 1', id: '1' },
          { name: 'Val 2', id: '2' },
          { name: 'Val 3', id: '3' },
          { name: 'Val 4', id: '4' },
          { name: 'Val 5', id: '5' },
        ],
        filteredOptions: [
          { name: 'Val 2', id: '2' },
          { name: 'Val 3', id: '3' },
          { name: 'Val 4', id: '4' },
          { name: 'Val 5', id: '5' },
        ],
        name: 'Kategori',
      },
      singleSelectValue: 1,
      singleSelectData: [
        { name: 'My first choice', id: 1 },
        { name: 'My second choice', id: 2 },
        { name: 'My third choice', id: 3 },
        { name: 'My fourth choice', id: 4 },
      ],
      rating: 2,
      input: '',
    };
  },
};
</script>
