<template>
  <svg
    v-if="isOn"
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    :class="{ star__pointer: !readOnly }"
    @click="toggle"
  >
    <path
      d="M12.0002 17.3103L6.12223 20.9003L7.72023 14.2003L2.49023 9.7203L9.35523 9.1703L12.0002 2.8103L14.6452 9.1703L21.5112 9.7203L16.2802 14.2003L17.8782 20.9003L12.0002 17.3103Z"
      fill="#F97840"
    />
  </svg>
  <svg
    v-else
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    :class="{ star__pointer: !readOnly }"
    @click="toggle"
  >
    <path
      d="M12.2608 16.5733L12.0002 16.4141L11.7396 16.5733L6.88747 19.5368L8.20659 14.006L8.27744 13.7089L8.04551 13.5103L3.72857 9.81239L9.39516 9.3584L9.69962 9.33401L9.8169 9.052L12.0002 3.80209L14.1836 9.052L14.3009 9.33401L14.6053 9.3584L20.2727 9.81239L15.955 13.5102L15.723 13.7089L15.7939 14.006L17.113 19.5368L12.2608 16.5733Z"
      stroke="#F97840"
    />
  </svg>
</template>

<script>
export default {
  name: 'Star',

  props: {
    value: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isOn: false,
    };
  },

  watch: {
    value(value) {
      this.isOn = value;
    },
  },

  mounted() {
    this.isOn = this.value;
  },

  methods: {
    toggle() {
      if (this.readOnly === true) return;
      this.isOn = !this.isOn;
      this.$emit('input', this.isOn);
    },
  },
};
</script>

<style scoped lang="scss">
.star {
  &__pointer {
    cursor: pointer;
  }
}
</style>
