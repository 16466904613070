<template>
  <div>
    <input v-model="opacity" type="range" />
    <img class="original" src="../assets/house.jpg" alt="original" />
    <img
      :style="{ opacity: opacity / 100 }"
      src="../assets/houselayer.jpg"
      alt="original"
    />
  </div>
</template>

<script>
export default {
  name: 'svglayers',
  data() {
    return {
      opacity: 0,
    };
  },
};
</script>

<style lang="scss" scoped>
div {
  width: 100vw;
  position: relative;
  input {
    position: absolute;
    z-index: 2;
  }
  img {
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
  }
}
</style>
