<template>
  <div class="checkboxes">
    <template v-for="option in options">
      <input
        :id="`${id}-${option.id}`"
        :key="option.id"
        :checked="selected.includes(option.id)"
        type="checkbox"
        @click="click($event, option.id)"
      />
      <label :key="`label-${option.id}`" :for="`${id}-${option.id}`">
        {{ option.name }}
      </label>
    </template>
  </div>
</template>

<script>
export default {
  name: 'Checkboxes',

  props: {
    value: {
      type: Array,
      default: () => [],
    },
    options: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      selected: [],
      id: null,
    };
  },

  watch: {
    selected() {
      this.$emit('input', this.selected);
    },
  },

  mounted() {
    this.id = this._uid;
    this.selected = [...this.value.map((o) => o.id)];
  },

  methods: {
    click(evt, id) {
      if (evt.target.checked) {
        this.select(id);
      } else {
        this.unselect(id);
      }
    },

    select(id) {
      if (!this.selected.includes(id)) {
        this.selected.push(id);
      }
    },

    unselect(id) {
      if (this.selected.includes(id)) {
        this.selected = [...this.selected.filter((o) => o !== id)];
      }
    },
  },
};
</script>

<style scoped>
input {
  display: none;
}
input + label {
  background-color: #fff;
  color: #6a30c9;
  border: 2px solid #6a30c9;
  box-sizing: border-box;
  border-radius: 4px;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
  display: block;
  text-align: center;
  padding: 8px;
  display: inline-block;
  margin: 0 8px;
}
input + label:active,
input:checked + label {
  color: #fff;
  background-color: #6a30c9;
}
</style>
