<template>
  <section>
    <ul>
      <DropDown
        v-for="(info, index) in questions"
        :key="info.id"
        :index="index"
        :active-index="activeIndex"
        :title="info.title"
        :text="info.text"
        :link="info.link"
        @clicked="openClose(index)"
      />
    </ul>
  </section>
</template>

<script>
import DropDown from '@/components/ui/Dropdown.vue';
// Add the script tag with ssr to make it detectable for google
export default {
  components: {
    DropDown,
  },
  data() {
    return {
      activeIndex: null,
      questions: [
        {
          title: 'En skön fråga?',
          text: ['Ett skönt svar', 'Superskönt faktiskt'],
        },
        {
          title: 'Ännu skön fråga?',
          text: ['Ännu ett skönt svar', 'Superskönt faktiskt'],
        },
      ],
    };
  },
  mounted() {
    let script = document.createElement('script');
    script.setAttribute('src', JSON.stringify(this.googleData));
    script.setAttribute('type', 'application/ld+json');
    document.head.appendChild(script);
  },
  computed: {
    googleData() {
      let questions = this.questions.map((q) => {
        let text = q.text.join(' ');
        return {
          '@type': 'Question',
          name: q.title,
          acceptedAnswer: {
            '@type': 'Answer',
            text: `<p>${text}</p>`,
          },
        };
      });
      return {
        '@context': 'https://schema.org',
        '@type': 'FAQPage',
        mainEntity: questions,
      };
    },
  },
  methods: {
    openClose(index) {
      if (this.activeIndex === index) {
        this.activeIndex = null;
      } else {
        this.activeIndex = index;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
section {
  width: 100%;
  ul {
    padding: 0;
  }
}
</style>
