<template>
  <li :style="listHeight">
    <div ref="label" class="label" @click="$emit('clicked')">
      <h4>{{ title }}</h4>
      <hr :class="{ vertical: !open }" />
      <hr v-show="!open" />
    </div>
    <div ref="content" class="content">
      <p v-for="t in text" :key="t">
        {{ t }}
      </p>
    </div>
  </li>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    text: {
      type: Array,
      default: null,
    },
    index: {
      type: Number,
      default: 0,
    },
    activeIndex: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      height: 0,
      labelMargin: 32,
    };
  },
  computed: {
    listHeight() {
      return `height: ${this.height}px`;
    },
    open() {
      if (this.index === this.activeIndex) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    open(open) {
      if (!open) {
        this.height = this.$refs.label.clientHeight + this.labelMargin;
      } else {
        this.height =
          this.$refs.label.clientHeight +
          this.$refs.content.clientHeight +
          this.labelMargin * 1.5;
      }
    },
  },
  mounted() {
    this.height = this.$refs.label.clientHeight + this.labelMargin;
  },
};
</script>

<style scoped lang="scss">
li {
  border-bottom: 1px solid rgba($color: #000000, $alpha: 0.2);
  overflow: hidden;
  transition: 0.6s ease-in-out height;
  min-height: 54px;
  list-style-type: none;
  padding: 0 8px;
  p {
    margin: 18px 0;
  }
  h4 {
    cursor: pointer;
  }
  .label {
    position: relative;
    margin: 16px 0;
    cursor: pointer;
    hr {
      width: 14px;
      height: 1px;
      border: none;
      background-color: #000;
      position: absolute;
      right: 8px;
      top: 4px;
      transition: 0.6s ease-in-out all;
    }
    .vertical {
      transform: rotate(-90deg);
      opacity: 1;
    }
  }
}
</style>
