<template>
  <div
    @click="hamburgerActive = !hamburgerActive"
    class="hamburger"
    :class="{ hamburger_active: hamburgerActive }"
  >
    <span class="line"></span>
    <span class="line"></span>
  </div>
</template>

<script>
export default {
  watch: {
    hamburgerActive: function (value) {
      this.$emit('toggle', value);
    },
  },
  data() {
    return {
      hamburgerActive: false,
    };
  },
};
</script>

<style lang="scss" scoped>
@import '../../styles/nav/_hamburger.scss';
</style>
