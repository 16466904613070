<template>
  <section class="category_section">
    <Showcase :title="'FAQ with google data (uses the dropdown component)'">
      <FAQ />
    </Showcase>
  </section>
</template>

<script>
// @ is an alias to /src
import Showcase from '@/components/Showcase.vue';
import FAQ from '@/components/blocks/Faq.vue';

export default {
  name: 'Home',
  components: {
    Showcase,
    FAQ,
  },
};
</script>
