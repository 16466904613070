<template>
  <div class="showcase">
    <h5>{{ title }}</h5>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'Showcase',
  props: {
    title: {
      type: String,
      default: 'title',
    },
  },
};
</script>

<style lang="scss" scoped>
.showcase {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1400px;
  min-height: 375px;
  justify-content: space-around;
  border: 1px dotted rgba($color: #000000, $alpha: 0.1);
  h5 {
    max-width: 50%;
    text-align: center;
    font-size: 16px;
  }
  hr {
    border: none;
    background-color: rgba($color: #000000, $alpha: 0.5);
    height: 1px;
    width: 80%;
    margin-top: 100px;
  }
}
</style>
