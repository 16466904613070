<template>
  <div class="multiselect">
    <label for="brand">{{ name }}</label>
    <div class="select-wrapper">
      <div class="cover" :class="{ 'cover-active': selectedValue.length > 0 }">
        <h5 v-if="selectedValue.length < 1">Välj {{ placeholderGetter }}</h5>
        <h5 v-else-if="selectedValue.length === 1">
          {{ selectedValue[0].name }}
        </h5>
        <h5 v-else>
          {{ selectedValue[0].name }} +{{ selectedValue.length - 1 }}
        </h5>
      </div>
      <select :name="name" multiple v-model="selectedValue" :id="name">
        <optgroup :label="name">
          <option
            v-for="o in options"
            :disabled="
              !availableOptions.find((a) => a[identifier] === o[identifier])
            "
            :key="o.name"
            :value="o"
          >
            {{ o.name }}
          </option>
        </optgroup>
      </select>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      default: null,
    },
    availableOptions: {
      type: Array,
      default: null,
    },
    clear: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: null,
    },
    identifier: {
      type: String,
      default: 'id',
    },
  },
  data() {
    return {
      selectedValue: [],
    };
  },
  watch: {
    selectedValue(value) {
      this.$emit('selected', value);
    },
    clear() {
      this.selectedValue = [];
    },
  },
  computed: {
    placeholderGetter() {
      return this.placeholder ? this.placeholder : this.name;
    },
  },
};
</script>

<style lang="scss" scoped>
.multiselect {
  width: 240px;
  .select-wrapper {
    position: relative;
    select,
    label {
      max-width: 100%;
    }
    select {
      width: 100%;
      border-radius: 10px;
      padding: 20px;
      font-size: 14px;
      opacity: 0;
    }
    .cover-active {
      ::after {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        position: absolute;
        content: '';
        height: 100%;
        width: 4px;
        right: 0;
        top: 0;
        background-color: green;
      }
    }
    .cover {
      position: absolute;
      display: flex;
      align-items: center;
      padding-left: 20px;
      width: 100%;
      height: 100%;
      background-color: rgba($color: #000000, $alpha: 0.05);
      z-index: 999;
      pointer-events: none;
      border-radius: 2px;
    }
  }
}
</style>
