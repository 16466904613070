<template>
  <div class="field">
    <input
      v-model="internValue"
      :class="{ input_error: errors.length || error, input_disabled: disabled }"
      :type="type"
      :name="name"
      :readonly="disabled"
      @keyup.enter="enter"
      @input="changed"
    />
    <label
      v-if="error"
      class="error_label"
      :class="{ label_active: internValue }"
      :for="name"
    >
      {{ error }}
    </label>
    <label
      v-else-if="!errors.length"
      :class="{ label_active: internValue }"
      :for="name"
      >{{ placeholder }}</label
    >
    <label
      v-else
      class="error_label"
      :class="{ label_active: internValue }"
      :for="name"
    >
      {{ errors[0] }}
    </label>
  </div>
</template>

<script>
export default {
  name: 'Field',

  props: {
    value: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      required: true,
    },
    errors: {
      type: Array,
      default: () => [],
    },
    error: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: 'text',
    },
    placeholder: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      internValue: null,
    };
  },

  watch: {
    value(value) {
      this.internValue = value;
    },
  },

  mounted() {
    this.internValue = this.value;
  },

  methods: {
    changed() {
      this.$emit('input', this.internValue);
    },

    enter() {
      this.$emit('enter');
    },
  },
};
</script>

<style lang="scss" scoped>
div {
  position: relative;
  width: 100%;
  .input_error {
    background-color: #ffdbdbed;
  }
  .input_disabled {
    opacity: 0.4;
  }
  input {
    box-sizing: border-box;
    font-weight: 400;
    padding: 1rem * 2.5 1rem 1rem;
    font-size: 1rem;
    border: 1px solid rgba($color: #000000, $alpha: 0.3);
    width: 100%;
  }
  .error_label {
    color: #8b2f40;
  }
  label {
    position: absolute;
    font-size: 0.9rem;
    left: 1rem;
    top: 50%;
    transform: translateY(-50%);
    color: rgba($color: #000, $alpha: 0.3);
    pointer-events: none;
    transition: 0.3s all ease-in-out;
    &::first-letter {
      text-transform: capitalize;
    }
  }
  input:focus ~ label,
  .label_active {
    font-size: 0.7em;
    top: 14px;
    border-radius: 20px;
  }
}
</style>
