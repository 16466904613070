<template>
  <div class="select_single" :class="{ select_single_noborder: !border }">
    <select v-model="modelValue" class="mobile_select" :value="value">
      <option :value="null" disabled selected>{{ placeholder }}</option>
      <option
        v-for="option in options"
        :key="option[optionkey]"
        :value="option[optionkey]"
      >
        {{ option[name] }}
      </option>
    </select>
    <div
      v-click-outside:open="toggleDesktopSelect"
      class="desktop_select"
      :class="{ 'desktop_select--open': open }"
    >
      <div class="label" @click.stop="toggleDesktopSelect">
        <span>{{ selectedName }}</span>
        <img src="@/assets/inputarrow.svg" alt="arrow.svg" />
      </div>
      <ul class="options">
        <li
          v-for="option in options"
          :key="option[optionkey]"
          class="option"
          :class="{ option_selected: option.id === value }"
          @click="emitDesktop(option[optionkey])"
        >
          {{ option[name] }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: [Number, String],
      default: null,
    },
    options: {
      type: Array,
      default: null,
    },
    optionkey: {
      type: String,
      default: 'id',
    },
    name: {
      type: String,
      default: 'name',
    },
    placeholder: {
      type: String,
      default: 'Choose',
    },
    border: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      open: false,
      modelValue: null,
    };
  },
  computed: {
    selectedName() {
      return this.value
        ? this.options.find((c) => c[this.optionkey] === this.value)[this.name]
        : this.placeholder;
    },
  },
  watch: {
    modelValue(value) {
      this.$emit('input', value);
    },
    value(value) {
      this.modelValue = value;
    },
  },
  mounted() {
    this.modelValue = this.value;
  },
  methods: {
    emitDesktop(id) {
      this.$emit('input', id);
      this.toggleDesktopSelect();
    },
    toggleDesktopSelect() {
      this.open = !this.open;
    },
  },
};
</script>

<style lang="scss" scoped>
$height: 54px;
$black: #000;
$bgColor: #fff;
$activeColor: #fff;
$activeBgColor: #0024ff;
$spacer: 16px;
$shadow: 0px 7px 14px rgba(0, 5, 82, 0.08);
$mobile: 750px;

.select_single {
  width: 80%;
  .desktop_select {
    @media (max-width: $mobile) {
      display: none;
    }
    width: 100%;
    cursor: pointer;
    position: relative;
    .label {
      height: $height;
      border-radius: $spacer;
      border: 1px solid $black;
      position: relative;
      display: flex;
      align-items: center;
      padding-left: $spacer;
      img {
        transition: 0.2s all ease-in;
        position: absolute;
        top: 50%;
        right: $spacer;
        transform: translateY(-50%);
      }
    }
    .options {
      width: 100%;
      position: absolute;
      background-color: $bgColor;
      z-index: 2;
      height: 0;
      overflow: hidden;
      box-shadow: $shadow;
      border-radius: $spacer;
      list-style-type: none;
      margin: 0;
      padding: 0;
      .option {
        padding: $spacer;
        position: relative;
        &::after {
          content: '';
          height: 1px;
          width: 100%;
          background-color: rgba($color: #000000, $alpha: 0.2);
          position: absolute;
          bottom: 0;
          left: 0;
        }
        &:last-child {
          &::after {
            content: none;
          }
        }
        &_selected {
          opacity: 0.5;
        }
        &:hover {
          background-color: $activeBgColor;
          color: $bgColor;
        }
      }
    }
    &--open {
      .options {
        height: auto;
      }
      .label {
        img {
          transform: rotate(180deg);
          top: 40%;
        }
      }
    }
  }
  .mobile_select {
    @media (min-width: $mobile) {
      display: none;
    }
    cursor: pointer;
    width: 100%;
    height: $height;
    position: relative;
    border-radius: $spacer;
    border: 1px solid $black;
    padding: 0 $spacer;
    background-image: url('../../assets/inputarrow.svg');
    background-repeat: no-repeat;
    background-color: $bgColor;
    background-position: top 22px right $spacer;
    -webkit-appearance: none;
    -moz-appearance: none;
  }
}
</style>
