<template>
  <section class="category_section">
    <Showcase :title="'Simple animated hamburger'">
      <Hamburger />
    </Showcase>
    <Showcase :title="'Primary button'">
      <Button :title="'Click me plz'" />
    </Showcase>
    <Showcase :title="'Secondary button'">
      <Button :title="'Click me plz'" :type="'secondary'" />
    </Showcase>
    <Showcase :title="'Ghost button'">
      <Button :title="'Click me plz'" :type="'ghost'" />
    </Showcase>
    <Showcase :title="'Inactive primary button'">
      <Button :title="'Click me plz'" :inActive="true" />
    </Showcase>
  </section>
</template>

<script>
// @ is an alias to /src
import Showcase from '@/components/Showcase.vue';
import Hamburger from '@/components/nav/Hamburger.vue';
import Button from '@/components/ui/Button.vue';

export default {
  name: 'Home',
  components: {
    Showcase,
    Hamburger,
    Button,
  },
};
</script>
