import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import SvgViews from '../views/SvgLayers.vue';
import Buttons from '../views/Buttons.vue';
import Inputs from '../views/Inputs.vue';
import Layout from '../views/Layout.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/buttons',
    name: 'Buttons',
    component: Buttons,
  },
  {
    path: '/layout',
    name: 'Layout',
    component: Layout,
  },
  {
    path: '/inputs',
    name: 'Inputs',
    component: Inputs,
  },
  {
    path: '/svg',
    name: 'SvgViews',
    component: SvgViews,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
