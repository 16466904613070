<template>
  <button
    class="dynamic_button"
    :class="typeOfButton"
    @click="$emit('clicked')"
  >
    {{ title }}
    <img v-if="image" :src="image" :alt="image" />
  </button>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: null,
    },
    image: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: 'primary',
    },
    inActive: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    typeOfButton() {
      return {
        dynamic_button_primary: this.type === 'primary',
        dynamic_button_secondary: this.type === 'secondary',
        dynamic_button_ghost: this.type === 'ghost',
        dynamic_button_inactive: this.inActive,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
$blue: #0024ff;
$white: #fff;
$black: #000;

.dynamic_button {
  font-weight: 600;
  font-size: 18px;
  transition: 0.3s all ease-in-out;
  padding: 14px 32px;
  border-radius: 128px;
  border: none;
  cursor: pointer;
  img {
    margin-left: 6px;
  }
  &:active {
    transform: scale(0.98);
  }
  &_primary {
    background-color: $blue;
    color: $white;
    box-shadow: 0px 5px 24px -3px rgba(56, 31, 209, 0.08);
    &:active {
      box-shadow: 0 0 3pt 2pt #0024ff;
    }
  }
  &_secondary {
    background-color: transparent;
    border: 2px solid $black;
    color: $black;
  }
  &_ghost {
    background-color: transparent;
    color: $blue;
  }
  &_inactive {
    opacity: 0.5;
    cursor: not-allowed !important;
  }
}
</style>
