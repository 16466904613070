import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import clickOutside from './directives/vClickOutside';

Vue.config.productionTip = false;
Vue.directive('clickOutside', clickOutside);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
