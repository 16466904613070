<template>
  <div>
    <Star v-model="stars[0]" :read-only="readOnly" @input="set(0)" />
    <Star v-model="stars[1]" :read-only="readOnly" @input="set(1)" />
    <Star v-model="stars[2]" :read-only="readOnly" @input="set(2)" />
    <Star v-model="stars[3]" :read-only="readOnly" @input="set(3)" />
    <Star v-model="stars[4]" :read-only="readOnly" @input="set(4)" />
  </div>
</template>

<script>
import Star from './Star.vue';

export default {
  name: 'Rating',

  components: {
    Star,
  },

  props: {
    value: {
      type: Number,
      default: 0,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      stars: [false, false, false, false, false],
    };
  },

  computed: {
    rating() {
      return this.stars.filter((s) => s).length;
    },
  },

  watch: {
    rating(value) {
      if (this.readOnly === false) {
        this.$emit('input', value);
      }
    },
    value(value) {
      this.setFromRating(value);
    },
  },

  mounted() {
    this.setFromRating(this.value);
  },

  methods: {
    set(index) {
      const value = this.stars[index];
      if (value === false && index === this.rating) {
        this.$nextTick(() => this.$set(this.stars, index, false));
        return;
      }
      if (value) {
        for (const k in [...Array(5).keys()]) {
          this.$set(this.stars, k, Boolean(k <= index));
        }
      } else {
        for (const k in [...Array(5).keys()].reverse()) {
          this.$set(this.stars, k, Boolean(k < index));
        }
        this.$nextTick(() => this.$set(this.stars, index, true));
      }
    },

    setFromRating(rating) {
      for (const k in [...Array(5).keys()]) {
        this.$set(this.stars, k, Boolean(k <= rating - 1));
      }
    },
  },
};
</script>
